import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useEffect, useRef } from "react";
import cn from "classnames";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import { Button, Col, Row } from "reactstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  FullBackground,
  GetStartedPageContainer,
  GetStartedPricingTable,
  GetStartedPricingTableUtils,
  Layout,
  LinkToOpenInNewWindow,
  SEO,
} from "components";
import {
  device,
  hardCodedAddressLookupData,
  pricingLookupTableData,
  routes,
  utilFunctions,
} from "utils";
import { getCaptchaToken } from "../../utils/captchaUtils";
import { navigate } from "gatsby";

const { getPlanName } = GetStartedPricingTableUtils;

const { countryList, requiredCountryFields, provincePattern, statePattern } =
  hardCodedAddressLookupData;

const summarySchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email address.")
    .required("Required"),
  phone: Yup.string()
    .matches(/^(\d|[-]){1,13}$/, "Must be a valid phone number.")
    .required("Required"),
  desiredStartDate: Yup.string().required("Required"),
  billingName: Yup.string().required("Required"),
  billingCompany: Yup.string().required("Required"),
  billingCountry: Yup.string().notRequired(),
  billingAddress: Yup.string().notRequired(),
  billingCity: Yup.string().notRequired(),
  billingLocality: Yup.string().notRequired(),
  billingState: Yup.string().notRequired(),
  billingProvince: Yup.string().notRequired(),
  billingCounty: Yup.string().notRequired(),
});

const GetStartedSummaryPage = ({ location }) => {
  // Check if summary has basic information to display. If not, that means
  // the user navigated to this url directly, so we would like to redirect them
  // back to the Get Started page
  // Note: useEffect throws error in console in local dev, but is fine in compiled preview/prod.
  useEffect(() => {
    // if (
    //   !location.state?.accountType ||
    //   !location.state?.selectedPlan ||
    //   !location.state?.data
    // ) {
    //   console.log("getStartedSummary redirecting to getStartedLanding");
    //   navigate(routes("getStartedLanding"), { replace: true });
    // }
    // Temporarily taking the self-pricing pages offline by redirecting to landing
    console.log("getStartedSummary redirecting to getStartedLanding");
    navigate(routes("getStartedLanding"), { replace: true });
  }, [location.state]);

  const { capacityParams } = pricingLookupTableData;

  const recaptchaRef = useRef(null);

  const title = "Get Started with ECL | Summary";
  const description = "View your personalized account details";
  // Keep defaults for testing purposes
  let accountType = location.state?.accountType || "Startup";
  let selectedPlan = location.state?.selectedPlan || "A la Carte";
  let discountAmount = location.state?.discountAmount || "0";
  let discountName = location.state?.discountName || "";
  let renewalDiscountAmount = location.state?.renewalDiscountAmount || "0";
  let renewalDiscountName = location.state?.renewalDiscountName || "";
  let startingAt = location.state?.startingAt;
  let privateTutoringCost = location.state?.privateTutoringCost;
  let certificateTrainingCost = location.state?.certificateTrainingCost;
  let pricingTableState = location.state?.data || {
    capacity: capacityParams.Basic.DisplayText,
    renewal: "1 year",
    licenses: "3",
    storage: "1",
    tutoring: "1",
    training: "Level 1 Open",
    level2: false,
    level3: false,
    level4: false,
    level5: false,
  };

  // The "billing" in the variable names is an artifact of having "mailing" at one point during dev.
  // Can be cleaned up once things settle.
  const initialData = location.state?.formData || {
    email: "",
    phone: "",
    desiredStartDate: "",
    billingName: "",
    billingCompany: "",
    billingCountry: "United States",
    billingAddress: "",
    billingCity: "",
    billingLocality: "",
    billingState: "",
    billingProvince: "",
    billingCounty: "",
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const onSubmit = async (formData, actions) => {
    const token = await getCaptchaToken(recaptchaRef);
    if (!token) {
      return;
    }

    const {
      email,
      phone,
      desiredStartDate: startDateRaw,
      billingName: fullName,
      billingCompany: companyName,
      billingAddress,
      billingCity,
      billingLocality,
      billingState,
      billingProvince,
      billingCounty,
      billingCountry,
    } = formData;
    const fullAddress = [
      billingAddress,
      billingCity,
      billingLocality,
      billingState,
      billingProvince,
      billingCounty,
      billingCountry,
    ].filter((field) => field !== "");
    let fullAddressString = "";
    for (const field of fullAddress.values()) {
      if (fullAddressString === "") {
        fullAddressString = fullAddressString.concat(field);
      } else {
        fullAddressString = fullAddressString.concat(", ", field);
      }
    }
    const desiredStartDate = utilFunctions.getFormattedDate(
      new Date(startDateRaw)
    );
    const submitDate = utilFunctions.getFormattedCurrentDate();
    const quoteExpirationDate = utilFunctions.getFormattedQuoteExpirationDate();
    const quoteName = fullName.replace(" ", "").toLowerCase();
    const quoteDate = utilFunctions.getFormattedCurrentDate(true);
    const quoteRand = Math.floor(Math.random() * Math.floor(999999));
    const quoteNumber = `${quoteName}-${quoteDate}-${quoteRand}`;

    const quoteParams = new URLSearchParams();
    quoteParams.append("fullName", fullName);
    quoteParams.append("companyName", companyName); // New
    quoteParams.append("address", fullAddressString); // New
    quoteParams.append("submitDate", submitDate);
    quoteParams.append("quoteNumber", quoteNumber); // New
    quoteParams.append("quoteExpirationDate", quoteExpirationDate); // New
    quoteParams.append("desiredStartDate", desiredStartDate);
    quoteParams.append("accountType", accountType);
    quoteParams.append("selectedPlan", selectedPlan);
    quoteParams.append("data", JSON.stringify(pricingTableState));
    const quoteUrl = `${routes("getStartedQuote")}?${quoteParams.toString()}`;
    const urlOrigin = location.origin || "https://www.emeraldcloudlab.com";
    const quoteUrlFullPath = `${urlOrigin}${quoteUrl}`;

    // Send form to Salesforce, open quote in new tab, and route to confirmation page
    fetch("https://go.emeraldcloudlab.com/l/1038213/2023-10-03/2kydr7", {
      method: "POST",
      mode: "no-cors",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        Email: email,
        AccountType: accountType,
        LastName: fullName,
        Company: companyName,
        Phone: phone,
        Country: billingCountry,
        Address: billingAddress,
        City: billingCity,
        State: billingState || billingProvince,
        DesiredStartDate: desiredStartDate,
        Description: quoteUrlFullPath,
      }),
    })
      .then(() => {
        const link = document.getElementById("quote-link");
        link.setAttribute("href", quoteUrl);
        link.click();
      })
      .then(() =>
        navigate(routes("getStartedConfirmation"), {
          state: {
            accountType,
            selectedPlan,
            discountAmount,
            discountName,
            renewalDiscountAmount,
            renewalDiscountName,
            startingAt,
            privateTutoringCost,
            certificateTrainingCost,
            data: pricingTableState,
            formData,
          },
        })
      )
      .catch((error) => alert(error));

    // Send confirmation email
    const {
      capacity,
      licenses,
      storage,
      renewal,
      tutoring,
      training: level1,
      level2,
      level3,
      level4,
      level5,
    } = pricingTableState;
    const selectedPlanName = getPlanName(selectedPlan);
    const training = [level1];
    if (level2) {
      training.push("Level 2 Private");
    }
    if (level3) {
      training.push("Level 3 Private");
    }
    if (level4) {
      training.push("Level 4 Private");
    }
    if (level5) {
      training.push("Level 5 Private");
    }
    const msg = {
      personalizations: [
        {
          to: [
            {
              email: formData.email,
            },
          ],
          dynamic_template_data: {
            fullName,
            companyName,
            address: fullAddressString,
            submitDate,
            quoteNumber,
            quoteExpirationDate,
            desiredStartDate,
            plan: {
              selectedPlanName,
              discountAmount: discountAmount !== "0" && `$${discountAmount}`,
              discountName,
              renewalDiscountAmount:
                renewalDiscountAmount !== "0" && `$${renewalDiscountAmount}`,
              renewalDiscountName,
              startingAt: `$${startingAt}`,
              privateTutoringCost:
                privateTutoringCost !== "0" && `$${privateTutoringCost}`,
              certificateTrainingCost:
                certificateTrainingCost !== "0" &&
                `$${certificateTrainingCost}`,
              accountType,
              capacity,
              licenses,
              storage: `${storage} TB`,
              renewal: selectedPlan === "Subscription" && renewal,
              tutoring: `${tutoring} month`,
              training,
              pricingLink: quoteUrlFullPath,
            },
          },
        },
      ],
      from: {
        email: "sales@emeraldcloudlab.com",
        name: "Emerald Cloud Lab",
      },
      template_id: "d-d33e66e6e80946a5be72404d6924fc38",
    };

    fetch("/.netlify/functions/send-confirmation-email", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(msg),
    })
      .catch((error) => alert(error))
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <FullBackground>
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <GetStartedPageContainer
          state={{
            data: pricingTableState,
            accountType,
          }}
          step="summary"
          showHeader
          showLearnMore
        >
          <Formik
            initialValues={initialData}
            validationSchema={summarySchema}
            onSubmit={onSubmit}
          >
            {(props) => {
              const { values, touched, errors, isSubmitting, initialValues } =
                props;
              const nameAndCompanyRow = (type) => {
                const nameWithType = `${type}Name`;
                const companyWithType = `${type}Company`;
                return (
                  <S.Row>
                    <S.Col xs={12} sm={6} className="single-item">
                      <label
                        htmlFor={nameWithType}
                        className="item-description"
                      >
                        Full Name
                      </label>
                      <S.Field
                        className={cn("name", type, {
                          error:
                            touched &&
                            touched[nameWithType] &&
                            errors &&
                            errors[nameWithType],
                        })}
                        id={nameWithType}
                        name={nameWithType}
                        type="text"
                      />
                      <ErrorMessage name={nameWithType}>
                        {(msg) => <div className="error-message">{msg}</div>}
                      </ErrorMessage>
                    </S.Col>
                    <S.Col xs={12} sm={6} className="single-item">
                      <label
                        htmlFor={companyWithType}
                        className="item-description"
                      >
                        Company / Organization
                      </label>
                      <S.Field
                        className={cn("company", type, {
                          error:
                            touched?.[companyWithType] &&
                            errors?.[companyWithType],
                        })}
                        id={companyWithType}
                        name={companyWithType}
                        type="text"
                      />
                      <ErrorMessage name={companyWithType}>
                        {(msg) => <div className="error-message">{msg}</div>}
                      </ErrorMessage>
                    </S.Col>
                  </S.Row>
                );
              };
              const addressRows = (type) => {
                const countryWithType = `${type}Country`;
                const addressWithType = `${type}Address`;
                const cityWithType = `${type}City`;
                const localityWithType = `${type}Locality`;
                const stateWithType = `${type}State`;
                const provinceWithType = `${type}Province`;
                const countyWithType = `${type}County`;
                const selectedCountry = values[countryWithType];
                const showCity = selectedCountry
                  ? requiredCountryFields[selectedCountry].includes("City")
                  : true;
                const showLocality =
                  !!requiredCountryFields[selectedCountry]?.includes(
                    "Locality"
                  );
                const showState =
                  !!requiredCountryFields[selectedCountry]?.includes("State");
                const showProvince =
                  !!requiredCountryFields[selectedCountry]?.includes(
                    "Province"
                  );
                const showCounty =
                  !!requiredCountryFields[selectedCountry]?.includes("County");
                return (
                  <>
                    <S.Row>
                      <S.Col className="single-item">
                        <label
                          htmlFor={countryWithType}
                          className="item-description"
                        >
                          Country
                        </label>
                        {/* In order to set default selection:
                            What doesn't work:
                              1. Set value/defaultValue on Field to pass to <select>
                              2. Set selected on <option>
                            Workaround:
                              Moved United States to the top in the hardcoded data to achieve the
                              default selection effect.
                          */}
                        <S.Field
                          className={cn("country dropdown", type, {
                            error:
                              touched?.[countryWithType] &&
                              errors?.[countryWithType],
                            empty: !values[countryWithType],
                          })}
                          id={countryWithType}
                          name={countryWithType}
                          component="select"
                        >
                          {countryList.map((country) => {
                            return (
                              <option
                                key={country}
                                value={country}
                                label={country}
                              >
                                {country}
                              </option>
                            );
                          })}
                        </S.Field>
                        <ErrorMessage name={countryWithType}>
                          {(msg) => <div className="error-message">{msg}</div>}
                        </ErrorMessage>
                      </S.Col>
                    </S.Row>
                    <S.Row>
                      <S.Col className="single-item">
                        <label
                          htmlFor={addressWithType}
                          className="item-description"
                        >
                          Street Address
                        </label>
                        <S.Field
                          className={cn("address", type, {
                            error:
                              touched?.[addressWithType] &&
                              errors?.[addressWithType],
                          })}
                          id={addressWithType}
                          name={addressWithType}
                          type="text"
                        />
                        <ErrorMessage name={addressWithType}>
                          {(msg) => <div className="error-message">{msg}</div>}
                        </ErrorMessage>
                      </S.Col>
                    </S.Row>
                    <S.Row>
                      {showCity && (
                        <S.Col xs={12} sm={6} className="single-item">
                          <label
                            htmlFor={cityWithType}
                            className="item-description"
                          >
                            City
                          </label>
                          <S.Field
                            className={cn("city", type, {
                              error:
                                touched?.[cityWithType] &&
                                errors?.[cityWithType],
                            })}
                            id={cityWithType}
                            name={cityWithType}
                            type="text"
                          />
                          <ErrorMessage name={cityWithType}>
                            {(msg) => (
                              <div className="error-message">{msg}</div>
                            )}
                          </ErrorMessage>
                        </S.Col>
                      )}
                      {showLocality && (
                        <S.Col xs={12} sm={6} className="single-item">
                          <label
                            htmlFor={localityWithType}
                            className="item-description"
                          >
                            Locality
                          </label>
                          <S.Field
                            className={cn("locality", type, {
                              error:
                                touched?.[localityWithType] &&
                                errors?.[localityWithType],
                            })}
                            id={localityWithType}
                            name={localityWithType}
                            type="text"
                          />
                          <ErrorMessage name={localityWithType}>
                            {(msg) => (
                              <div className="error-message">{msg}</div>
                            )}
                          </ErrorMessage>
                        </S.Col>
                      )}
                      {showState && (
                        <S.Col xs={12} sm={4} className="single-item">
                          <label
                            htmlFor={stateWithType}
                            className="item-description"
                          >
                            State
                          </label>
                          <S.Field
                            className={cn("state dropdown", type, {
                              error:
                                touched?.[stateWithType] &&
                                errors?.[stateWithType],
                              empty: !values[stateWithType],
                            })}
                            id={stateWithType}
                            name={stateWithType}
                            component="select"
                          >
                            <option value="" label="Select" />
                            {statePattern[selectedCountry].map((state) => {
                              return (
                                <option key={state} value={state} label={state}>
                                  {state}
                                </option>
                              );
                            })}
                          </S.Field>
                          <ErrorMessage name={stateWithType}>
                            {(msg) => (
                              <div className="error-message">{msg}</div>
                            )}
                          </ErrorMessage>
                        </S.Col>
                      )}
                      {showProvince && (
                        <S.Col xs={12} sm={4} className="single-item">
                          <label
                            htmlFor={provinceWithType}
                            className="item-description"
                          >
                            Province
                          </label>
                          <S.Field
                            className={cn("province dropdown", type, {
                              error:
                                touched?.[provinceWithType] &&
                                errors?.[provinceWithType],
                              empty: !values[provinceWithType],
                            })}
                            id={provinceWithType}
                            name={provinceWithType}
                            component="select"
                          >
                            <option value="" label="Select" />
                            {provincePattern[selectedCountry].map(
                              (province) => {
                                return (
                                  <option
                                    key={province}
                                    value={province}
                                    label={province}
                                  >
                                    {province}
                                  </option>
                                );
                              }
                            )}
                          </S.Field>
                          <ErrorMessage name={provinceWithType}>
                            {(msg) => (
                              <div className="error-message">{msg}</div>
                            )}
                          </ErrorMessage>
                        </S.Col>
                      )}
                      {showCounty && (
                        <S.Col xs={12} sm={4} className="single-item">
                          <label
                            htmlFor={countyWithType}
                            className="item-description"
                          >
                            County
                          </label>
                          <S.Field
                            className={cn("county", type, {
                              error:
                                touched?.[countyWithType] &&
                                errors?.[countyWithType],
                            })}
                            id={countyWithType}
                            name={countyWithType}
                            type="text"
                          />
                          <ErrorMessage name={countyWithType}>
                            {(msg) => (
                              <div className="error-message">{msg}</div>
                            )}
                          </ErrorMessage>
                        </S.Col>
                      )}
                    </S.Row>
                  </>
                );
              };
              return (
                <Form
                  data-netlify
                  data-netlify-recaptcha
                  data-netlify-honeypot="bot-field"
                  name="Quote Request"
                >
                  <S.Row style={{ display: "none" }}>
                    <S.Col className="section-header">
                      <label>Don't fill this out if you're human</label>
                      <Field name="bot-field" type="text" />
                    </S.Col>
                  </S.Row>
                  <S.Row>
                    <S.Col className="section-header">
                      Enter Contact Information
                    </S.Col>
                  </S.Row>
                  {nameAndCompanyRow("billing")}
                  <S.Row>
                    <S.Col xs={12} sm={6} className="single-item">
                      <label htmlFor="email" className="item-description">
                        Email
                      </label>
                      <S.Field
                        className={cn("email", {
                          error: touched?.email && errors?.email,
                        })}
                        id="email"
                        name="email"
                        type="email"
                      />
                      <ErrorMessage name="email">
                        {(msg) => <div className="error-message">{msg}</div>}
                      </ErrorMessage>
                    </S.Col>
                    <S.Col xs={12} sm={6} className="single-item">
                      <label htmlFor="phone" className="item-description">
                        Phone
                      </label>
                      <S.Field
                        className={cn("phone", {
                          error: touched?.phone && errors?.phone,
                        })}
                        id="phone"
                        name="phone"
                        type="tel"
                      />
                      <ErrorMessage name="phone">
                        {(msg) => <div className="error-message">{msg}</div>}
                      </ErrorMessage>
                    </S.Col>
                  </S.Row>
                  {addressRows("billing")}
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
                    theme="dark"
                  />
                  <S.Row>
                    <S.Col className="single-item desired-start-date">
                      <label
                        htmlFor="desiredStartDate"
                        className="item-description"
                      >
                        Desired Start Date
                      </label>
                      <S.Field
                        className={cn("desiredStartDate", {
                          error:
                            touched?.desiredStartDate &&
                            errors?.desiredStartDate,
                          empty: !values.desiredStartDate,
                        })}
                        id="desiredStartDate"
                        name="desiredStartDate"
                        type="date"
                        max="9999-12-31"
                      />
                      <ErrorMessage name="desiredStartDate">
                        {(msg) => <div className="error-message">{msg}</div>}
                      </ErrorMessage>
                    </S.Col>
                  </S.Row>
                  <S.Row>
                    <S.Col>
                      <div className="section-divider" />
                    </S.Col>
                  </S.Row>
                  <S.Row>
                    <S.Col className="section-header">
                      Price Quote Summary
                    </S.Col>
                  </S.Row>
                  <S.Row>
                    <S.Col className="pricing-table">
                      <GetStartedPricingTable
                        step="summary"
                        accountType={accountType}
                        state={pricingTableState}
                      />
                    </S.Col>
                  </S.Row>
                  <S.Row>
                    <S.Col className="fine-print final">
                      All services are offered subject to ECL's standard{" "}
                      <LinkToOpenInNewWindow
                        className="sm hover-underline"
                        href={routes("tos")}
                      >
                        Terms of Service
                      </LinkToOpenInNewWindow>
                    </S.Col>
                  </S.Row>
                  <S.Row>
                    <S.Col className="buttons">
                      {Object.keys(errors).length > 0 &&
                        Object.keys(touched).length ===
                          Object.keys(initialValues).length &&
                        errors.constructor === Object && (
                          <div className="error-message button">
                            Please fix the highlighted errors
                          </div>
                        )}
                      {/* should do window.open above and get rid of this anchor element */}
                      <a
                        style={{ display: "none" }}
                        id="quote-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={routes("getStartedQuote")}
                      />
                      <S.SubmitButton
                        className="confirm-purchase dark"
                        block
                        color="primary"
                        disabled={isSubmitting}
                        size="lg"
                        type="submit"
                      >
                        {isSubmitting
                          ? "Generating..."
                          : "Generate price quote"}
                      </S.SubmitButton>
                    </S.Col>
                  </S.Row>
                  {/* Hidden fields necessary for including additional fields that are added
                  on the JavaScript side when the form is submitted */}
                  <S.Row className="hidden" style={{ display: "none" }}>
                    <S.Col>
                      <label>Submit Date</label>
                      <S.Field id="submitDate" name="submitDate" />
                    </S.Col>
                    <S.Col>
                      <label>Quote Number</label>
                      <S.Field id="quoteNumber" name="quoteNumber" />
                    </S.Col>
                    <S.Col>
                      <label>Quote Expiration Date</label>
                      <S.Field
                        id="quoteExpirationDate"
                        name="quoteExpirationDate"
                      />
                    </S.Col>
                    <S.Col>
                      <label>Account Type</label>
                      <S.Field id="accountType" name="accountType" />
                    </S.Col>
                    <S.Col>
                      <label>Selected Plan</label>
                      <S.Field id="selectedPlan" name="selectedPlan" />
                    </S.Col>
                    <S.Col>
                      <label>Quote URL</label>
                      <S.Field id="quoteUrl" name="quoteUrl" />
                    </S.Col>
                  </S.Row>
                </Form>
              );
            }}
          </Formik>
        </GetStartedPageContainer>
      </Layout>
    </FullBackground>
  );
};

export default GetStartedSummaryPage;

const S = {};

S.Row = styled(Row)``;

S.Col = styled(Col)`
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2rem;
  // border: 1px solid magenta;
  padding-bottom: 3rem;
  &.pricing-table {
    padding-bottom: 1rem;
  }
  &.control {
    padding-bottom: 3.7rem;
  }
  &.section-header {
    color: #ffffff;
    font-size: 2.4rem;
    line-height: 3rem;
    // border: 1px solid yellow;
  }
  &.fine-print {
    color: #b1bac2;
    font-weight: 300;
    &.final {
      color: #768594;
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
  &.single-item {
    display: flex;
    flex-direction: column;
  }
  &.desired-start-date {
    padding-bottom: 4rem;
  }
  .item-description {
    margin-bottom: 0.6rem;
  }
  .section-divider {
    border-top: 1px solid #455059;
  }
  input.error {
    border-color: #e95e5e;
  }
  .error-message {
    color: #e95e5e;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1.7rem;
    margin-top: 2px;
    &.button {
      white-space: nowrap;
      @media all and ${device.md} {
        margin: 1.6rem 0 1rem 13.4rem;
      }
    }
  }
  &.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    @media all and ${device.md} {
      flex-direction: row;
    }
  }
`;

S.Field = styled(Field)`
  width: auto;
  &.dropdown {
    @media all and ${device.sm} {
      width: 25rem;
    }
  }
  &.desiredStartDate {
    @media all and ${device.sm} {
      width: 25rem;
    }
  }
  &.radio,
  &.checkbox {
    height: 0;
    border: 0;
    input {
      margin-right: 0.6rem;
    }
  }
`;

S.SubmitButton = styled(Button)`
  &.confirm-purchase.dark {
    padding: 0;
    margin-bottom: 1rem;

    @media all and ${device.md} {
      margin-left: 2rem;
    }

    @media all and ${device.sm} {
      width: 19rem;
    }
  }
`;
